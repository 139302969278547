<template>
  <div class="MeusDados">
    <div>
      <div v-if="carregando == true" class="carregando">
        <!--img
          width="100"
          src="https://bboneapp.s3.amazonaws.com/evo-white.png"
        /-->
        <br />
        <br />
        <br />Carregando.. Aguarde
      </div>
      <div class="form" v-if="evento">
        <b-form @submit.stop.prevent="onSubmit" class="text-left">
          <b-card
            :title="`Pré Abertura - ${evento.Eventos_TipoEvento.descricao}`"
            tag="article"
            class="mb-2"
          >
            <b-card-text class="pt-4">
              <b-form-group
                id="input-group-2"
                label="Tipo de Colisão"
                label-for="input-2"
                v-if="verificaExibicao('idTipoColisao')"
              >
                <b-select
                  :options="tiposColisao"
                  name="Tipo de Colisao"
                  id="Tipo"
                  v-model="form.idTipoColisao"
                  v-validate="{ required: true }"
                  :state="validateState('Tipo de Colisao')"
                  :class="validateClass('Tipo de Colisao')"
                ></b-select>
              </b-form-group>
              <b-form-group
                id="input-group-4"
                v-if="verificaExibicao('policiaCompareceu')"
              >
                <b-form-checkbox
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="form.policiaCompareceu"
                  switch
                  >Polícia Compareceu no Local</b-form-checkbox
                >
              </b-form-group>
              <b-form-group
                id="input-group-4"
                v-if="verificaExibicao('sindicanteNoLocal')"
              >
                <b-form-checkbox
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="form.sindicanteNoLocal"
                  switch
                  >Sindicante no Local</b-form-checkbox
                >
              </b-form-group>
              <b-form-group
                id="input-group-4"
                v-if="verificaExibicao('veiculoEmFuncionamento')"
              >
                <b-form-checkbox
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="form.veiculoEmFuncionamento"
                  switch
                  >Veículo em Funcionamento</b-form-checkbox
                >
              </b-form-group>
              <b-form-group
                id="input-group-4"
                v-if="verificaExibicao('contatoAssistencia')"
              >
                <b-form-checkbox
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="form.contatoAssistencia"
                  switch
                  >Foi Feito contato com a Assistência</b-form-checkbox
                >
              </b-form-group>
              <b-form-group
                v-if="form.contatoAssistencia == true"
                id="input-group-2"
                label="Data do Contato com a Assistência"
                label-for="input-2"
              >
                <datetime
                  name="Data do Contato com a Assistência"
                  type="datetime"
                  :phrases="{ ok: 'OK', cancel: 'Fechar' }"
                  v-model="form.dataHoraContatoAssistencia"
                  :max-datetime="maxDate"
                  auto
                  v-validate="{ required: true }"
                  :state="validateState('Data do Contato com a Assistência')"
                  :input-class="`form-control ${validateClass(
                    'Data do Contato com a Assistência',
                  )}`"
                >
                  <template slot="button-cancel"> </template>
                  <template slot="button-confirm">
                    <font-awesome-icon
                      icon="check"
                    ></font-awesome-icon> </template
                ></datetime>
              </b-form-group>
              <b-form-group
                id="input-group-4"
                v-if="verificaExibicao('veiculoProtegidoSoleChuva')"
              >
                <b-form-checkbox
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="form.veiculoProtegidoSoleChuva"
                  switch
                  >Veículo Protegido de Sol e Chuva</b-form-checkbox
                >
              </b-form-group>
              <b-form-group
                id="input-group-4"
                v-if="verificaExibicao('acionamentoParaTerceiro')"
              >
                <b-form-checkbox
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="form.acionamentoParaTerceiro"
                  switch
                  >Acionamento para Terceiro</b-form-checkbox
                >
              </b-form-group>
              <b-form-group
                id="input-group-4"
                v-if="verificaExibicao('veiculoEmOficina')"
              >
                <b-form-checkbox
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="form.veiculoEmOficina"
                  switch
                  >Veículo em Oficina</b-form-checkbox
                >
              </b-form-group>
              <b-form-group
                id="input-group-4"
                v-if="verificaExibicao('possuiVitima')"
              >
                <b-form-checkbox
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="form.possuiVitima"
                  switch
                  >Possui Vítima(s)</b-form-checkbox
                >
              </b-form-group>

              <b-form-group
                id="input-group-4"
                v-if="verificaExibicao('solicitaCarroReserva')"
              >
                <b-form-checkbox
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="form.solicitaCarroReserva"
                  switch
                  >Acionar Carro Reserva (Caso Possua)</b-form-checkbox
                >
              </b-form-group>
              <b-form-group
                id="input-group-4"
                label="Número do Boletim de Ocorrência"
                v-if="verificaExibicao('numeroBoletim')"
              >
                <b-form-input
                  id="input-2"
                  v-model="form.numeroBoletim"
                  placeholder="Número do Boletim de Ocorrência"
                  name="Número do Boletim de Ocorrência"
                  v-validate="{ required: true }"
                  :state="validateState('Número do Boletim de Ocorrência')"
                  :class="validateClass('Número do Boletim de Ocorrência')"
                ></b-form-input>
              </b-form-group>
            </b-card-text>
          </b-card>
          <b-button
            pill
            ref="btnAvancar"
            size="lg"
            type="submit"
            variant="info"
            class="btn btn-block mb-5"
            :style="`background-color:${temaCor.botaoFormularios};`"
            >Avançar</b-button
          >
        </b-form>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import jwt from 'jsonwebtoken';
import service from '../../services';
import moment from 'moment';
import { Settings } from 'luxon';
Settings.defaultLocale = 'pt-br';
import locale from './../../locale';
export default {
  name: 'DashBoard',
  props: ['evento', 'temaCor'],
  watch: {
    evento(value) {
      // console.log("Chamou o preencher");
      this.preencheForm();
    },
  },
  data() {
    return {
      decoded: {},
      menuOpen: false,
      carregando: false,
      maxDate: moment().format(),
      listPerguntasPorTipo: '',
      tiposColisao: [],
      form: {
        policiaCompareceu: null,
        sindicanteNoLocal: null,
        veiculoEmFuncionamento: null,
        contatoAssistencia: null,
        dataHoraContatoAssistencia: null,
        veiculoProtegidoSoleChuva: null,
        solicitaCarroReserva: null,
        acionamentoParaTerceiro: null,
        veiculoEmOficina: null,
        possuiVitima: null,
        numeroBoletim: null,
        idTipoColisao: null,
      },
    };
  },
  components: {},
  methods: {
    verificaExibicao(tipo) {
      return this.listPerguntasPorTipo.indexOf(tipo) >= 0;
    },
    preencheForm() {
      this.listPerguntasPorTipo =
        this.evento.Eventos_TipoEvento.perguntasPreAbertura;
      if (this.listPerguntasPorTipo == '') {
        this.$router.push(`/editarEvento/${this.evento.idEvento}/3`);
      }
      this.form.policiaCompareceu = this.evento.policiaCompareceu;
      this.form.sindicanteNoLocal = this.evento.sindicanteNoLocal;
      this.form.veiculoEmFuncionamento = this.evento.veiculoEmFuncionamento;
      this.form.contatoAssistencia = this.evento.contatoAssistencia;
      this.form.dataHoraContatoAssistencia =
        this.evento.dataHoraContatoAssistencia;
      this.form.veiculoProtegidoSoleChuva =
        this.evento.veiculoProtegidoSoleChuva;
      this.form.solicitaCarroReserva = this.evento.solicitaCarroReserva;
      this.form.acionamentoParaTerceiro = this.evento.acionamentoParaTerceiro;
      this.form.veiculoEmOficina = this.evento.veiculoEmOficina;
      this.form.possuiVitima = this.evento.possuiVitima;
      this.form.numeroBoletim = this.evento.numeroBoletim;
      if (this.tiposColisao.length == 0) {
        this.preencheForm();
      } else {
        var aux = this.tiposColisao.find(
          (x) => x.value != null && x.value.id == this.evento.idTipoColisao,
        );
        this.form.idTipoColisao = aux.value;
      }
    },
    popToast() {
      // Use a shorter name for this.$createElement
      const h = this.$createElement;

      var msg = '';
      var arrMsg = this.$validator.errors.all();
      var arrAux = [];
      arrMsg.forEach((element) => {
        arrAux.push(h('p', {}, element));
      });
      // Create the message
      const vNodesMsg = h('p', { class: ['text-center', 'mb-0'] }, arrAux);

      // Pass the VNodes as an array for message and title
      this.$bvToast.toast([vNodesMsg], {
        title: 'Atenção!',
        solid: true,
        variant: 'danger',
        toaster: 'b-toaster-top-full',
        appendToast: false,
        autoHideDelay: 2500,
      });
    },
    onSubmit() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.popToast();
          return;
        }
        this.$emit('send', this.form);
      });
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    buscaDadosSelects(model, array, campoDescricao, campoPK) {
      this.carregando = true;
      var url = `${model}/list/idEmpresa/${this.form.idEmpresa}`;

      if (model == 'domTipoColisao') url = `${model}/list`;
      service.getAll('g', {}, url).then((res) => {
        this[array].push({ text: 'Selecione', value: null });
        res.data.forEach((element) => {
          this[array].push({
            value: { id: element[campoPK], valor: element[campoDescricao] },
            text: element[campoDescricao],
          });
        });
        console.log(this[array]);
        this.carregando = false;
      });
    },
    validateClass(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        if (!this.veeErrors.has(ref) == false) {
          return 'border border-danger ';
        } else {
          return 'border border-success ';
        }
      }
      return null;
    },
  },
  mounted() {
    this.$validator.localize('en', locale);
    this.buscaDadosSelects(
      'domTipoColisao',
      'tiposColisao',
      'descricao',
      'idTipoColisao',
    );
  },
};
</script>

<style lang="scss" scoped></style>
