<template>
  <div class="MeusDados">
    <div>
      <div v-if="carregando == true" class="carregando">
        <!--img
          width="100"
          src="https://bboneapp.s3.amazonaws.com/evo-white.png"
        /-->
        <br />
        <br />
        <br />Carregando.. Aguarde
      </div>
      <div class="form" v-if="evento">
        <b-form @submit.stop.prevent="onSubmit" class="text-left">
          <b-card title="Dados do Associado" tag="article" class="mb-2">
            <b-card-text>
              <b>Nome:</b> {{ evento.Eventos_Associado.nome }} <br />
              <b>CPF / CNPJ:</b> {{ evento.Eventos_Associado.cpfcnpj }} <br />
              <b>Celular:</b> {{ evento.Eventos_Associado.celular }}
            </b-card-text>
          </b-card>
          <b-card title="Dados do Veículo" tag="article" class="mb-2">
            <b-card-text>
              <b>Placa:</b> {{ evento.Eventos_Veiculo.placa }} <br />
              <b>Chassi:</b> {{ evento.Eventos_Veiculo.chassi }} <br />
              <b>Marca:</b> {{ evento.Eventos_Veiculo.veiculoMarca }} <br />
              <b>Modelo:</b> {{ evento.Eventos_Veiculo.veiculoModelo }} -
              {{ evento.Eventos_Veiculo.anoFabricacao }} /
              {{ evento.Eventos_Veiculo.anoModelo }} <br />
            </b-card-text>
          </b-card>
          <b-form-group
            id="input-group-2"
            label="Data do Evento"
            label-for="input-2"
          >
            <datetime
              name="Data do Evento"
              type="datetime"
              :phrases="{ ok: 'OK', cancel: 'Fechar' }"
              v-model="form.dataHoraEvento"
              :max-datetime="maxDate"
              auto
              v-validate="{ required: true }"
              :state="validateState('Data do Evento')"
              :input-class="`form-control ${validateClass('Data do Evento')}`"
            >
              <template slot="button-cancel"> </template>
              <template slot="button-confirm">
                <font-awesome-icon icon="check"></font-awesome-icon> </template
            ></datetime>
          </b-form-group>
          <b-button
            pill
            ref="btnAvancar"
            size="lg"
            type="submit"
            variant="info"
            class="btn btn-block mb-5"
            :style="`background-color:${temaCor.botaoFormularios};`"
            >Confirmar</b-button
          >
        </b-form>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import jwt from 'jsonwebtoken';
import service from '../../services';
import moment from 'moment';
import { Settings } from 'luxon';
Settings.defaultLocale = 'pt-br';
import locale from './../../locale';
export default {
  name: 'DashBoard',
  props: ['evento', 'temaCor'],
  watch: {
    evento(value) {
      // console.log("Chamou o preencher");
      this.preencheForm();
    },
  },
  data() {
    return {
      decoded: {},
      menuOpen: false,
      carregando: false,
      maxDate: moment().format(),
      form: {
        dataHoraEvento: null,
      },
    };
  },
  components: {},
  methods: {
    preencheForm() {
      this.form.dataHoraEvento = this.evento.dataHoraEvento;
    },
    popToast() {
      // Use a shorter name for this.$createElement
      const h = this.$createElement;

      var msg = '';
      var arrMsg = this.$validator.errors.all();
      var arrAux = [];
      arrMsg.forEach((element) => {
        arrAux.push(h('p', {}, element));
      });
      // Create the message
      const vNodesMsg = h('p', { class: ['text-center', 'mb-0'] }, arrAux);

      // Pass the VNodes as an array for message and title
      this.$bvToast.toast([vNodesMsg], {
        title: 'Atenção!',
        solid: true,
        variant: 'danger',
        toaster: 'b-toaster-top-full',
        appendToast: false,
        autoHideDelay: 2500,
      });
    },
    onSubmit() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.popToast();
          return;
        }
        this.$emit('send', this.form);
      });
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    validateClass(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        if (!this.veeErrors.has(ref) == false) {
          return 'border border-danger ';
        } else {
          return 'border border-success ';
        }
      }
      return null;
    },
  },
  mounted() {
    this.$validator.localize('en', locale);
  },
};
</script>

<style lang="scss" scoped></style>
