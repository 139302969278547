<template>
  <div class="MeusDados">
    <div>
      <div v-if="carregando == true" class="carregando">
        <!--img
          width="100"
          src="https://bboneapp.s3.amazonaws.com/evo-white.png"
        /-->
        <br />
        <br />
        <br />Carregando.. Aguarde
      </div>
      <div class="form" v-if="evento">
        <b-form @submit.stop.prevent="onSubmit" class="text-left">
          <div class="fotos-necessarias mt-4">
            <h5>Fotos Veículo</h5>
            <hr />
            <b-row>
              <b-col cols="6" v-for="item in fotos" :key="item.id">
                <b-card
                  :title="item.nomePosicao"
                  tag="article"
                  class="mb-3"
                  :class="`${item.nome ? 'hero' : ''}`"
                  :style="`${
                    item.nome ? `background-image:url('${item.nome}');` : ''
                  }`"
                >
                  <div
                    v-if="item.aprovada != true"
                    class="containericone"
                    @click="openModalFoto(item)"
                  >
                    <font-awesome-icon
                      class="icone mr-2"
                      :icon="['fa', 'camera']"
                    />
                    <font-awesome-icon class="icone" :icon="['fa', 'folder']" />
                  </div>
                  <b-badge v-if="item.aprovada == true" variant="success"
                    >Aprovada</b-badge
                  >
                  <b-badge
                    v-if="item.aprovada == false && item.motivoReprovacao"
                    variant="danger"
                    >Reprovada</b-badge
                  >
                </b-card>
              </b-col>
            </b-row>
          </div>
          <b-button
            pill
            ref="btnAvancar"
            size="lg"
            type="submit"
            variant="info"
            class="btn btn-block mb-5"
            :style="`background-color:${temaCor.botaoFormularios};`"
            >Avançar</b-button
          >
        </b-form>
        <b-modal
          v-model="modalFotosNaoPreenchidas"
          hide-footer
          centered
          :title="`Atenção`"
        >
          <div class="alert alert-warning legenda">
            Existem Fotos / Documentos necessários que ainda nao foram
            preenchidos, deseja continuar?
          </div>
          <center>
            <b-button
              pill
              ref="btnAvancar"
              size="lg"
              @click="continuarSemFoto()"
              variant="success"
              class="btn mb-5"
              >Sim, Continuar</b-button
            >
          </center>
        </b-modal>

        <b-modal
          v-model="modalFoto"
          hide-footer
          centered
          :title="`${fotoSelecionada.nomePosicao}`"
        >
          <componenteUpFoto
            :idEvento="evento.idEvento"
            :foto="fotoSelecionada"
            :temaCor="temaCor"
            @upload="preencheImagem"
          />
        </b-modal>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import jwt from 'jsonwebtoken';
import service from '../../services';
import moment from 'moment';
import { Settings } from 'luxon';
Settings.defaultLocale = 'pt-br';
import locale from './../../locale';
import componenteUpFoto from './componente_upFoto';
export default {
  name: 'DashBoard',
  props: ['evento', 'temaCor'],
  watch: {
    evento(value) {
      // console.log("Chamou o preencher");
      this.preencheForm();
    },
    cep: function (newValue, oldValue) {
      console.log(newValue, oldValue, this.cep);
      if (this.cep)
        if (this.cep.length == 10) {
          this.buscarCep();
        }
    },
  },
  components: { componenteUpFoto },
  data() {
    return {
      render: false,
      decoded: {},
      menuOpen: false,
      carregando: false,
      cep: null,
      encontrouCep: null,
      mostraErroGeo: false,
      modalFoto: false,
      modalFotosNaoPreenchidas: false,
      maxDate: moment().format(),
      estados: [],
      cidades: [],
      fotos: [],
      fotoSelecionada: {},
      exibeCheck: true,
      exibeForm: true,
      form: {},
    };
  },
  methods: {
    getIP() {
      fetch('https://api.ipify.org?format=json')
        .then((response) => response.json())
        .then((response) => {
          console.log(response.ip);
          this.form.ipAceite = response.ip;
        });
    },
    getLocation() {
      if (navigator.geolocation) {
        console.log('getLocation was called', navigator.geolocation);
        navigator.geolocation.getCurrentPosition(
          this.showPosition,
          this.positionError,
        );
      }
    },
    showPosition(position) {
      this.form.cordenadas = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
        altitude: null,
        altitudeAccuracy: null,
        accuracy: 43,
      };
      console.log(
        'Latitude: ' +
          position.coords.latitude +
          '<br>Longitude: ' +
          position.coords.longitude,
      );
    },
    positionError() {
      console.log('erro');
      this.mostraErroGeo = true;
    },
    preencheImagem(obj) {
      this.modalFoto = false;
      console.log(obj, this.evento.idEvento);
      this.fotos.forEach((element) => {
        console.log(element.id_modeloFoto, obj.foto.id_modeloFoto);
        if (element.id_modeloFoto == obj.foto.id_modeloFoto) {
          element.nome = obj.url;
          element.motivoReprovacao = '';
          element.aprovada = null;
          element.atualizada = true;
          element.coordenadas = obj.coordenadas;
          console.log(element.nome);
        }
      });
      console.log(this.fotos);
    },
    openModalFoto(item) {
      this.fotoSelecionada = item;
      this.modalFoto = true;
    },
    verificaDadosAssociado() {},
    preencheForm() {
      if (this.evento.Eventos_Veiculo && this.render == false) {
        this.buscaFotosToUpload();
      } else this.preencheForm();
    },
    popToast() {
      // Use a shorter name for this.$createElement
      const h = this.$createElement;

      var msg = '';
      var arrMsg = this.$validator.errors.all();
      var arrAux = [];
      arrMsg.forEach((element) => {
        arrAux.push(h('p', {}, element));
      });
      // Create the message
      const vNodesMsg = h('p', { class: ['text-center', 'mb-0'] }, arrAux);

      // Pass the VNodes as an array for message and title
      this.$bvToast.toast([vNodesMsg], {
        title: 'Atenção!',
        solid: true,
        variant: 'danger',
        toaster: 'b-toaster-top-full',
        appendToast: false,
        autoHideDelay: 2500,
      });
    },
    onSubmit() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.popToast();
          return;
        }
        this.form.fotos = this.fotos.filter(
          (x) => x.nome != null && x.atualizada == true,
        );
        this.form.idPessoa = this.evento.Eventos_Veiculo.idVeiculo;
        var fotosSemPreenchimento = this.fotos.filter(
          (x) => x.nome == null || (x.aprovada == 0 && !x.atualizada),
        );

        if (fotosSemPreenchimento.length > 0) {
          this.modalFotosNaoPreenchidas = true;
          return;
        }
        this.$emit('send', this.form);
      });
    },
    continuarSemFoto() {
      this.modalFotosNaoPreenchidas = false;
      this.$emit('send', this.form);
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    validateClass(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        if (!this.veeErrors.has(ref) == false) {
          return 'border border-danger ';
        } else {
          return 'border border-success ';
        }
      }
      return null;
    },
    buscaDadosSelects(model, array, campoDescricao, campoPK) {
      this.carregando = true;
      var url = `${model}/list/idEmpresa/${this.form.idEmpresa}`;

      if (model == 'domUf') url = `${model}/list`;
      service.getAll('g', {}, url).then((res) => {
        this[array].push({ text: 'Selecione', value: null });
        res.data.forEach((element) => {
          this[array].push({
            value: { id: element[campoPK], valor: element[campoDescricao] },
            text: element[campoDescricao],
          });
        });
        console.log(this[array]);
        this.carregando = false;
      });
    },
    buscaDadosSelectsCidades() {
      this.carregando = true;
      this.cidades = [];
      service
        .getAll('g', {}, `domMunicipio/list/codUf/${this.form.estado.id}`)
        .then((res) => {
          res.data.forEach((element) => {
            this.cidades.push({
              value: element.nomeCidade,
              text: element.nomeCidade,
            });
          });

          this.carregando = false;
        });
    },
    buscaFotosToUpload() {
      this.carregando = true;
      this.render = true;
      this.fotos = [];
      var obj = {};
      obj.idEvento = this.evento.idEvento;
      obj.tipoPessoa = 'Veiculo';
      obj.idPessoa = this.evento.Eventos_Veiculo.idVeiculo;

      service.post('Eventos', 'buscaFotosToUpload', obj).then((res) => {
        console.log(res);
        this.fotos = res.data;
        console.log(this.fotos);
        if (this.fotos.length == 0) {
          this.$router.push(`/editarEvento/${this.evento.idEvento}/9`);
        }
        this.carregando = false;
      });
    },
  },
  mounted() {
    this.getIP();
    this.getLocation();
    this.$validator.localize('en', locale);
    this.buscaDadosSelects('domUf', 'estados', 'nomeUf', 'codUf');
    this.preencheForm();
  },
};
</script>

<style lang="scss" scoped>
.containericone {
  border: 1px solid #e7e7e7;
  width: 50%;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  margin: 0 auto;
}

.fotos-necessarias {
  article {
    min-height: 150px;
    text-align: center;
    background-repeat: no-repeat;
    background-size: cover;
    h4 {
      font-size: 14px;
      margin-bottom: 20px;
    }
  }
}

.hero::before {
  content: '';
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid green;
}
.hero {
  .card-body {
    z-index: 2;
  }
}
</style>
